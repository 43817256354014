import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')));
/* ****Routes***** */

/* ****Administration***** */
const Admin = Loadable(lazy(() => import('../views/senagripro/Administration/Admin')));
const PaymentManagement = Loadable(lazy(() => import('../views/senagripro/Administration/PaymentManagement')));
const ProjetAgricoleManagent = Loadable(lazy(() => import('../views/senagripro/Administration/ProjetAgricoleManagent')));
const PackageMembersList = Loadable(lazy(() => import('../views/senagripro/Administration/PackageMembersList')));
const AdhesionProjetList = Loadable(lazy(() => import('../views/senagripro/Administration/AdhesionProjetList')));


/* ****Membre***** */
const UserHome = Loadable(lazy(() => import('../views/senagripro/Membre/UserHome')));
const TerresAgricoles = Loadable(lazy(() => import('../views/senagripro/Membre/TerresAgricoles')));


/* ****GENERAL***** */
const Accueil = Loadable(lazy(() => import('../views/senagripro/General/Accueil')));
const MonCompte = Loadable(lazy(() => import('../views/senagripro/General/MonCompte')));
const Forum = Loadable(lazy(() => import('../views/senagripro/General/Forum')));

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/Senagripro/Accueil" /> },
      { path: '/dashboards/starter', exact: true, element: <Dashboard /> },

      { path: '/Admin/Accueil', exact: true, element: <Admin /> },
      { path: '/Admin/PaymentManagement', exact: true, element: <PaymentManagement /> },
      { path: '/Admin/ProjetAgricoleManagent', exact: true, element: <ProjetAgricoleManagent /> },
      { path: '/Admin/PackageMembersList', exact: true, element: <PackageMembersList /> },
      { path: '/Admin/listeAdhesionproject', exact: true, element: <AdhesionProjetList /> },

      { path: '/Membre/Accueil', exact: true, element: <UserHome /> },
      { path: '/Membre/TerresAgricoles', exact: true, element: <TerresAgricoles /> },

      { path: '/Senagripro/Accueil', exact: true, element: <Accueil /> },
      { path: '/Senagripro/MonCompte', exact: true, element: <MonCompte /> },
      { path: '/Senagripro/Forum', exact: true, element: <Forum /> },

      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
